import * as React from 'react';
import moment from 'moment';
import Slider from 'react-slick';

import Text from 'components/core/Text';
import AchievementsTypes from '../../Achievements';
import { ACHIEVEMENT_TYPES } from '../../../constants';

import {
  Arrows,
  Content,
  CloseIcon,
  BackgroundStyled,
  Title,
  SubTitle,
  ReachedDateTitle,
  ReachedDateValue,
  Achievement,
  AchievementBadge,
} from './styles';

const SliderArrows = ({ className, to, onClick }) => {
  return (
    <Arrows
      theme="white"
      themeMobile="white"
      arialLabel={to}
      onClick={onClick}
      className={`button button--text button--icon ${className}`}
    />
  );
};

const SETTINGS = {
  // autoplay: true,
  arrows: true,
  dots: true,
  speed: 500,
  prevArrow: <SliderArrows to="prev" />,
  nextArrow: <SliderArrows to="next" />,
  infinite: false,
  swipe: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 765,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        swipe: true,
        dots: false,
        arrows: false,
        infinite: false,
      },
    },
  ],
};

class Achievements extends React.Component {
  handleCloseAchievements = () => {
    const { requestFetchAchievements } = this.props;

    requestFetchAchievements({ markAsViewed: true });
  };

  renderAchievement = achievement => {
    const achievementType = `type_${achievement.type}`;
    const achievementValue = `value_${achievement.timesValue}`;
    const type = 'earned';

    // Get the achievement component collection for this type
    const AchievementEntity = AchievementsTypes[achievementType];

    if (!AchievementEntity) {
      // eslint-disable-next-line no-console
      console.warn(`No achievement entity found for type: ${achievementType}`);
      return null;
    }

    // For profile/special achievements, look up by name
    if (achievement.type === 4 || achievement.type === 5) {
      // Convert name to snake_case for component lookup
      const componentKey = achievement.name
        .toLowerCase()
        .replace(/ /g, '_')
        .replace(/[^a-z0-9_]/g, '');

      // Try to find the achievement component by name
      const valueGroups = Object.keys(AchievementEntity);
      const matchingGroup = valueGroups.find(valueGroup =>
        AchievementEntity[valueGroup]?.[type]?.displayName?.toLowerCase().includes(componentKey),
      );

      if (matchingGroup) {
        const AchievementComponent = AchievementEntity[matchingGroup][type];
        return <AchievementComponent />;
      }
    }

    // For standard achievements, look up by value
    const AchievementComponent = AchievementEntity?.[achievementValue]?.[type];

    if (!AchievementComponent) {
      // eslint-disable-next-line no-console
      console.warn(
        `No achievement component found for ${achievementType}.${achievementValue}.${type}`,
      );
      return null;
    }

    return <AchievementComponent />;
  };

  renderAchievementTitle = achievement => {
    // Use achievement name if provided, otherwise fall back to type mapping
    return (
      achievement.name ||
      ACHIEVEMENT_TYPES[achievement.type] ||
      `Achievement Type ${achievement.type}`
    );
  };

  renderAchievements = () => {
    const { notViewedAchievements } = this.props;

    return notViewedAchievements && notViewedAchievements.length
      ? notViewedAchievements.map(notViewedAchievement => {
          return (
            <Achievement key={notViewedAchievement.id}>
              <AchievementBadge>{this.renderAchievement(notViewedAchievement)}</AchievementBadge>
              <Text
                variant="captionSmall"
                weight="bold"
                color="black"
                transform="uppercase"
                align="center"
                mb={7}
              >
                {this.renderAchievementTitle(notViewedAchievement)}
              </Text>
              <ReachedDateTitle>
                <Text variant="textSmall" tag="h4" align="center" color="gray">
                  {notViewedAchievement.description}
                </Text>
              </ReachedDateTitle>
              <ReachedDateValue>
                <Text variant="textSmall" weight="bold" color="gray" align="center">
                  {moment(notViewedAchievement.earnedAt).format('dddd, MMM DD')}
                </Text>
              </ReachedDateValue>
            </Achievement>
          );
        })
      : null;
  };

  render() {
    const { notViewedAchievements } = this.props;

    return (
      <>
        <BackgroundStyled onClick={this.handleCloseAchievements} />
        <Content>
          <CloseIcon onClick={this.handleCloseAchievements} />
          <Title>
            <Text variant="promoHeader" color="grayExtraLight" align="center" isCondensed>
              achievement
            </Text>
            <SubTitle>
              <Text variant="displayX" tag="div" color="black" align="center" transform="uppercase">
                reached
              </Text>
            </SubTitle>
          </Title>
          {notViewedAchievements && notViewedAchievements.length ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Slider {...SETTINGS}>{this.renderAchievements()}</Slider>
          ) : null}
        </Content>
      </>
    );
  }
}

export default Achievements;
